<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    :persistent="load"
  >
    <v-card :disabled="load">
      <v-card-title>
        {{ nuevo ? 'Nuevo' : 'Editar' }} talonario
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pb-sm-4 pb-0">
        <v-form ref="form">
          <v-row class="pt-8">
            <v-col cols="12" sm="4" class="py-0">
              <v-autocomplete
                v-model="talon.tipo_id"
                label="Tipo"
                item-text="nombre"
                item-value="id"
                :items="talonario_tipos.filter(t => t.inhabilitado != 1 || talon.tipo_id == t.id)"
                :rules="[rules.required]"
                validate-on-blur
                autofocus
                outlined
                dense
                @change="ejecutivo_obligatorio = talonario_tipos.find(t => t.id == talon.tipo_id).ejecutivo; delete talon.ejecutivo_codigo"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="5" class="py-0">
              <v-text-field
                v-model.trim="talon.responsable"
                label="Responsable"
                :rules="[rules.required]"
                :readonly="talon.ejecutivo_codigo != null"
                :filled="talon.ejecutivo_codigo != null"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" md="3" class="py-0">
              <v-autocomplete
                v-model="talon.estado_id"
                label="Estado"
                item-text="nombre"
                item-value="id"
                :items="talonario_estados"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-text-field
                v-model.trim="talon.emision"
                label="Nº Emisión"
                type="number"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-text-field
                v-model.trim="talon.num_inicio"
                label="Nº Inicio"
                type="number"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                @blur="completar_numero()"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-text-field
                v-model.trim="talon.num_fin"
                label="Nº Fin"
                type="number"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-text-field
                v-model.trim="talon.primer_num"
                label="1º Número"
                type="number"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" md="3" class="py-0">
              <FechaPicker
                v-model="talon.fecha"
                label="Asignación"
              />
            </v-col>
            <v-col cols="12" sm="4" md="3" class="py-0">
              <v-autocomplete
                v-model="talon.sucursal_codigo"
                label="Sucursal"
                item-text="nombre"
                item-value="id"
                :items="sucursales"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                @change="delete talon.ejecutivo_codigo"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="5" class="py-0">
              <v-autocomplete
                v-model="talon.ejecutivo_codigo"
                label="Ejecutivo"
                item-text="nombre"
                item-value="codigo"
                :items="ejecutivos.filter(ej => ej.sucursal == talon.sucursal_codigo && ej.inhabilitado != 1)"
                :rules="ejecutivo_obligatorio == 1 ? [rules.required] : []"
                :validate-on-blur="ejecutivo_obligatorio == 1"
                :clearable="ejecutivo_obligatorio != 1"
                outlined
                dense
                @change="seleccionar_responsable()"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-row class="d-flex justify-end py-3 px-3">
        <BtnConfirmar
          v-if="p_eliminar && !nuevo"
          nombre="Eliminar"
          icono="fas fa-trash"
          clase="mr-2 mt-sm-0 mt-3"
          color="red"
          :texto="`¿Desea <strong>eliminar</strong> el talonario?`"
          :dark="true"
          :disabled="load"
          @action="eliminar()"
        />
        <v-spacer></v-spacer>
        <v-btn
          class="mt-sm-0 mt-3"
          color="error"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          clase="ml-2 mt-sm-0 mt-3"
          :loading="load"
          @action="guardar()"
        />
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 *  Modal para editar, crear o eliminar un talonario
 * 
 *  Notas: este componente emite acciones (@nuevo, @editar, @eliminar) al padre caundo se crea o edita un talonario (en caso de estar definidas)
 */
import { mapState } from 'vuex'
import { get_nombre_obj_arr } from '../../util/utils'
import moment from 'moment'
import BtnConfirmar from '../util/BtnConfirmar'
import FechaPicker from '../util/FechaPicker'

export default {
  data () {
    return {
      ejecutivo_obligatorio: 0,
      load: false,
      nuevo: false,
      talon: {},
      rules: {
        required: value => !!value || 'Campo requerido'
      }
    }
  },
  props: {
    value: Boolean,
    p_talon: Object    // en caso de ser nuevo enviar null
  },
  computed: {
    ...mapState(['sucursales', 'ejecutivos']),
    ...mapState('talonarios', ['talonario_tipos', 'talonario_estados', 'p_eliminar']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar,
    FechaPicker
  },
  watch: {
    async dialog (val) {
      if (val) {
        if (this.p_talon) {
          this.nuevo = false
          // crea una copia del prop p_talon para editarlo
          this.talon = JSON.parse(JSON.stringify(this.p_talon))
        } else {
          this.nuevo = true
          this.talon = {
            num_fin: null,
            primer_num: null,
            fecha: moment(new Date()).format('DD/MM/YYYY')
          }
        }
      } else {
        this.$refs.form.resetValidation()
      }
    }
  },
  methods: {
    completar_numero () {
      this.talon.num_fin = parseInt(this.talon.num_inicio) + 49
      this.talon.primer_num = this.talon.num_inicio
    },
    seleccionar_responsable () {
      if (this.talon.ejecutivo_codigo) {
        this.talon.responsable = get_nombre_obj_arr(this.ejecutivos, 'codigo', this.talon.ejecutivo_codigo, 'nombre')
      }
    },
    async eliminar () {
      this.$store.state.loading = true
      await this.$store.dispatch('talonarios/eliminar', this.p_talon.id)
        .then((res) => {
          this.dialog = false
          // emite la accion definifa en el padre
          this.$emit('eliminar', this.p_talon)
          // muestra mensaje
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async guardar () {
      if (this.$refs.form.validate()) {
        this.load = true
        if (this.nuevo) {
          await this.$store.dispatch('talonarios/nuevo', this.talon)
            .then((res) => {
              this.dialog = false
              this.talon.id = res.id
              // emite la accion definifa en el padre
              this.$emit('nuevo', this.talon)
              // muestra mensaje
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success'
              })
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
        }
        else {
          // solo edita si realmente cambio algun valor
          if (JSON.stringify(this.talon) != JSON.stringify(this.p_talon)) {
            await this.$store.dispatch('talonarios/editar', this.talon)
              .then((res) => {
                this.dialog = false
                // obtiene los nombres de los datos que se muestran en el datatable
                this.talon.tipo_nombre = get_nombre_obj_arr(this.talonario_tipos, 'id', this.talon.tipo_id, 'nombre')
                this.talon.estado_nombre = get_nombre_obj_arr(this.talonario_estados, 'id', this.talon.estado_id, 'nombre')
                this.talon.sucursal_nombre = get_nombre_obj_arr(this.sucursales, 'id', this.talon.sucursal_codigo, 'nombre')
                this.talon.ejecutivo_nombre = get_nombre_obj_arr(this.ejecutivos, 'codigo', this.talon.ejecutivo_codigo, 'nombre')
                // emite la accion definifa en el padre
                this.$emit('editar', this.talon)
                // muestra mensaje
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success'
                })
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
          }
          else {
            this.dialog = false
            this.$store.dispatch('show_snackbar', {
              text: 'No se realizaron cambios en el talonario: los campos nuevos son iguales a los anteriores',
              color: 'orange',
              timeout: 4000
            })
          }
        }
        this.load = false
      }
    }
  }
}
</script>